import {CHANGE_LANGUAGE_FINISH, CHANGE_LANGUAGE_START, LanguageAction} from '../actions/languageAction';
import {Languages} from '../types/types';
import {getCurrentLangType} from '../helpers/currentLanguageHelper';

const initialStore: Languages  = getCurrentLangType();

export function languageReducer(state: Languages = initialStore, action: LanguageAction): Languages {
    if (action.type === CHANGE_LANGUAGE_FINISH) {
            return action.payload;
    }
    return state;
}
