export const LOAD_OFFER_START = 'LOAD_OFFER_START';
export const LOAD_OFFER_FINISH = 'LOAD_OFFER_FINISH';
export const LOAD_OFFER_FAIL = 'LOAD_OFFER_FAIL';

export type LOAD_OFFER_START_TYPE = typeof LOAD_OFFER_START;
export type LOAD_OFFER_FINISH_TYPE = typeof LOAD_OFFER_FINISH;
export type LOAD_OFFER_FAIL_TYPE = typeof LOAD_OFFER_FAIL;

export interface ILoadOfferStartAction {
    type: LOAD_OFFER_START_TYPE;
    payload: {
        lang: string;
    }
}

export interface ILoadOfferFinishAction {
    type: LOAD_OFFER_FINISH_TYPE;
    lang: string;
    text: string;
}

export interface ILoadOfferFailAction {
    type: LOAD_OFFER_FAIL_TYPE;
    payload: number;
    error: boolean;
}

export type OfferAction = ILoadOfferStartAction | ILoadOfferFinishAction | ILoadOfferFailAction;
