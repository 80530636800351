import React, {Component, ReactNode} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';

import {getSortedJournalByYears} from '../../../helpers/getJournalIndex';
import {IContentInfo} from '../../../types/types';
import {PressContentSlider} from '../PressContentSlider/PressContentSlider';

import './PressContentList.css';
import {Translation} from 'react-i18next';

const pressContentList: ClassNameFormatter = cn('PressContentList');

export interface IPressContentSlider extends IClassNameProps {
    pressContent: IContentInfo[];
    alias: string;
    page: string;
}

export class PressContentList extends Component <IPressContentSlider> {
    renderPressSliders = (): ReactNode[] => {
        const journalsByYears = getSortedJournalByYears(this.props.pressContent);
        const journalsByYearsArray = Object.values(journalsByYears);

        return (
            journalsByYearsArray.map((journal, index) => {
                return (
                    <div key={index} className={pressContentList('Item')}>
                        <Translation>
                            {
                                () =>
                                    <h3 className={pressContentList('ItemTitle')}>
                                        <span>{journal[0].tags[1].display_name}</span>
                                    </h3>
                            }
                        </Translation>
                        <PressContentSlider
                            className={pressContentList('ItemSlider')}
                            pressContent={journal}
                            page={this.props.page}
                            categoryAlias={this.props.alias}/>
                    </div>
                )
            })
        )
    };

    render() {
        return (
            <div className={pressContentList()}>
                {this.renderPressSliders()}
            </div>
        )
    }
}
