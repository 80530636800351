import React, {Component, ReactNode} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {classnames} from '@bem-react/classnames';
import {IClassNameProps} from '@bem-react/core';

import {checkCategoryProps} from '../../helpers/checkCategoryProps';
import {ICategory} from '../../types/types';
import {CategoryItem} from '../CategoryItem/CategoryItem';

import './CategoryList.css';
import {PRESS_ALIAS} from '../../constants/constants';

export interface ICategoryListPageProps extends IClassNameProps {
    categoriesData: ICategory[];
}

export class CategoryList extends Component <ICategoryListPageProps> {
    getCategoriesItems = (categoriesData: ICategory[]): ReactNode[] => {
        const newCategoriesList: ICategory[] = [];
        // категория "пресса" д.б. первой
        categoriesData.map((category: ICategory) => {
            if (category.alias === PRESS_ALIAS) {
                newCategoriesList.unshift(category);
            } else {
                newCategoriesList.push(category);
            }
        });

        return (
            newCategoriesList.map((category: ICategory, index: number) => {
                if (checkCategoryProps(category)) {
                    return (
                        <CategoryItem name={category.name} key={category.alias} contentData={category.contents}
                                      id={category.id}
                                      alias={category.alias} page={'main'}/>
                    )
                }
            })
        )
    };

    public render() {
        const categoryList: ClassNameFormatter = cn('CategoryList');
        const {categoriesData} = this.props;
        const categoriesList = this.getCategoriesItems(categoriesData);

        return (
            <div className={classnames(categoryList(), this.props.className)}>
                {categoriesList}
            </div>
        );
    }
}
