import React, {Component} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';

import {IContentCard} from '../../helpers/contentHelper';

import './ContentListFull.css';
import {ContentItemPreviewContainer} from '../ContentItemPreview/ContentItemPreviewContainer';
import {COMMON_CONTENT_CARD_TYPE} from "../../constants/constants";
import {MusicContentItemContainer} from "../MusicContentItem/MusicContentItemContainer";

export interface IContentListFull extends IClassNameProps {
    cards: IContentCard[];
    alias: string;
    page: string;
}

const contentListFull: ClassNameFormatter = cn('ContentListFull');

export class ContentListFull extends Component <IContentListFull> {

    getContentItems = (content: IContentCard[]) => {
        const {alias} = this.props;

        return (
            content.map((contentItem: IContentCard, index) => {
                let size: string =
                (index === 0 || (index === 7) || (index === 15) ||
                !(index%8)) && (index < 30) && (index < content.length - 3) ? 'big' : 'small';
                const {cardType, data} = contentItem;
                if (cardType === COMMON_CONTENT_CARD_TYPE) {
                    const [contentData] = data;
                    return (
                        <ContentItemPreviewContainer className={contentListFull('Item', {size: size})}
                                                     id={contentData.id}
                                                     key={contentData.id}
                                                     title={contentData.name}
                                                     description={contentData.description}
                                                     tags={contentData.tags}
                                                     order={index}
                                                     previews={contentData.previews}
                                                     type={contentData.type}
                                                     url={contentData.file_hash}
                                                     categoryAlias={alias}
                                                     page={this.props.page}
                                                     uidx={contentData.uidx}
                                                     size={size}/>
                    )
                } else {
                    return (
                        <MusicContentItemContainer className={contentListFull('Item', {type: cardType})} key={index}
                                                   order={index} categoryAlias={alias} page={this.props.page} data={data}
                        />
                    )
                }
            })
        )
    };

    render() {
        const {cards} = this.props;

        return (
            <div className={contentListFull()}>
                <div className={contentListFull('ItemsWrapper')}>
                    {this.getContentItems(cards)}
                </div>
            </div>
        )
    }
}
