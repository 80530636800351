import React, {Component, RefObject} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';
import * as Cookies from 'js-cookie';

import {ICategory, Languages, PageType} from '../../types/types';
import {CategoryList} from '../CategoryList/CategoryList';
import {Header} from '../Header/Header';


import './MainPage.css';
import {HeaderContainer} from '../Header/HeaderContainer';
import {classnames} from "@bem-react/classnames";
import {Player} from "../Player/Player";
import {PlayerContext} from '../App/App';

export interface IMainPageProps extends IClassNameProps {
    categoriesData?: ICategory[];
}

const mainPage: ClassNameFormatter = cn('MainPage');

export class MainPage extends Component<IMainPageProps> {
    private myRef: RefObject<Player>;

    constructor(props: IMainPageProps) {
        super(props);
        this.myRef = React.createRef();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        // Cookies.remove('lastUrl');
        const {categoriesData} = this.props;
        if (categoriesData) {
            return (

                <section className={mainPage()}>
                    <Player src={''} ref={this.myRef}/>
                    <PlayerContext.Provider value={this.myRef}>
                    <HeaderContainer
                        className={mainPage('Header')}
                        categoriesData={categoriesData}
                        page={PageType.typeMain}
                        onChange={(lang: Languages) => {
                        }}/>
                    <section className={mainPage('Content')}>
                        <CategoryList className={mainPage('CategoryListPage')} categoriesData={categoriesData}/>
                    </section>
                    </PlayerContext.Provider>
                </section>

            )
        }

        return null;
    }
}
