import React, {Component, ReactNode} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';

import {getRandomContentItem} from '../../helpers/getRandomContentItem';
import {ICategory, IContentInfo} from '../../types/types';

import './ContentAdditional.css';
import {ContentItemPreviewContainer} from '../ContentItemPreview/ContentItemPreviewContainer';

export interface IContentAdditionalProps extends IClassNameProps {
    category: ICategory;
    contentId: number;
    page: string;
}

const contentAdditional: ClassNameFormatter = cn('ContentAdditional');

export class ContentAdditional extends Component<IContentAdditionalProps> {

    renderAdditionalContentItems = (category: ICategory, idArray: number[]): ReactNode[] => {
        const {contents}: ICategory = category;
        const additionalContents: IContentInfo[] = contents.filter((content) => {
            return (idArray.indexOf(content.id) !== -1);
        });

        return(
            additionalContents.map((content: IContentInfo, index: number): ReactNode => {
                let size: string;
                index === 0 ? size = 'big' : size = 'small';
                return(
                    <ContentItemPreviewContainer
                        className={contentAdditional('ContentItem')}
                        id={content.id}
                        title={content.name}
                        previews={content.previews}
                        type={content.type}
                        tags={content.tags}
                        key={content.id}
                        order={index}
                        url={content.file_hash}
                        categoryAlias={category.alias}
                        page={this.props.page}
                        description={content.description}
                        uidx={content.uidx}
                        size={size}
                    />
                )
            })
        )
    };

    render() {
        const {category, contentId, page} = this.props;
        const idArray = getRandomContentItem(category.contents, contentId).slice(-5);

        return (
            <div className={contentAdditional()}>
                {this.renderAdditionalContentItems(category, idArray)}
            </div>
        )
    }
}
