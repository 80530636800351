import React, {Component} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {classnames} from '@bem-react/classnames';
import {IClassNameProps} from '@bem-react/core';

import Cross from '../../Icons/Cross.svg';

import './CloseButton.css';

export interface ICloseButtonState extends IClassNameProps {
    onClose(): void;
}

export class CloseButton extends Component <ICloseButtonState> {
    render() {
        const closeButton: ClassNameFormatter = cn('CloseButton');
        return(
            <div className={classnames(closeButton(), this.props.className)} onClick={this.props.onClose}>
                <img src={Cross}/>
            </div>
        )
    }
}
