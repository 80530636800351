import {applyMiddleware, createStore, Store} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import {createEpicMiddleware} from 'redux-observable';

import {CombinedAction} from '../actions';
import {rootEpic} from '../epics';
import {rootReducer} from '../reducers';
import {IAppStore} from '../types/types';

export function initStore() {
    const epicMiddleware = createEpicMiddleware<CombinedAction, CombinedAction, IAppStore>();

    const store: Store<IAppStore, CombinedAction> = createStore(
        rootReducer,
        composeWithDevTools(applyMiddleware(epicMiddleware)),
    );

    epicMiddleware.run(rootEpic);

    return store;
}
