export interface GetDataResponse {
   name: string;
   categories: any[];
   authorized?: boolean;
   status?: string;
}

export type GetManifestResponse = string[];

export interface IDataResponse {
    status: number;
    json?: GetDataResponse
}

export interface IManifestResponse {
    status: number;
    json?: GetManifestResponse;
}

export interface ITextResponse {
    status: number;
    text?: string;
}

// запрос для получения категорий
export function getData (url: string): Promise<IDataResponse> {
  return fetch(url,{ credentials: 'include' })
      .then(async(response) => {
          if(response.status >= 400) {
              return {
                  status: response.status
              }
          }
          const json = await response.json();
          return {
              status: response.status,
              json: json,
          }
      }
  );
}

// запрос для получения markup (content, offer, footer)
export async function getText(url: string): Promise<ITextResponse> {
    return fetch(url, { credentials: 'include' })
        .then(async(response) => {
            if(response.status >= 400) {
                return {
                    status: response.status
                }
            }
            const text = await response.text();

            return {
                status: response.status,
                text: text,
            }
        }
    );
}

//запрос для получения manifest для журнала
export function getManifest (url: string): Promise<IManifestResponse> {
    return fetch(url,{ credentials: 'include' })
        .then(async(response) => {
                if(response.status >= 400) {
                    return {
                        status: response.status
                    }
                }
                const json = await response.json();
                return {
                    status: response.status,
                    json: json,
                }
            }
        );
}


