import React, {Component} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';
import Swiper from 'react-id-swiper';

import {IContentInfo} from '../../../types/types';

import './PressContentSlider.css';
import {PressContentItemContainer} from '../PressContentItem/PressContentItemContainer';
import {classnames} from "@bem-react/classnames";
import LazyLoad from "react-lazyload";

const pressContentSlider: ClassNameFormatter = cn('PressContentSlider');

export interface IPressContentSlider extends IClassNameProps {
    pressContent: IContentInfo[];
    categoryAlias: string;
    page: string;
}

export class PressContentSlider extends Component<IPressContentSlider> {
    getContentItems = () => {
        const {pressContent} = this.props;
        return (
            pressContent.map((content: IContentInfo, index: number) => {
                return (
                    <PressContentItemContainer content={content}
                                               className={classnames(pressContentSlider('Item'), 'swiper-slide')}
                                               key={index}/>
                )
            })
        )
    };

    render() {
        let nameOfPage;
        switch (this.props.page) {
            case 'main':
                nameOfPage = 'MainPage';
                break;
            case 'category':
                nameOfPage = 'CategoryPge';
                break;
            default:
                nameOfPage = 'CommonPage';
        }

        const settings = {
            spaceBetween: 10,
            slidesPerView: 2,
            slidesPerGroup: 1,
            centeredSlides: true,
            grabCursor: true,
            loop: true,
            autoplay: false,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                560: {
                    slidesPerView: 2,
                    slidesPerGroup: 1,
                    centeredSlides: true,
                },
                720: {
                    slidesPerView: 3,
                    slidesPerGroup: 1,
                    centeredSlides: true,
                },
                1020: {
                    slidesPerView: 6,
                    slidesPerGroup: 1,
                    centeredSlides: true,
                }
            },
            lazy: {
                loadPrevNext: true,
            },
        };

        return (
            <div className={pressContentSlider({ofPage: nameOfPage})}>
                <LazyLoad scroll={true}>
                    <Swiper  {...settings}>
                        {this.getContentItems()}
                    </Swiper>
                </LazyLoad>
            </div>
        )
    }
}
