import {IContentInfo} from '../types/types';

export function checkContentProps(contentItem: IContentInfo) {
    if (contentItem.id &&
        contentItem.name &&
        contentItem.tags[0] &&
        contentItem.previews[0] &&
        contentItem.type &&
        contentItem.file_hash &&
        contentItem.uidx) {
        return true;
    }
}
