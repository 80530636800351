import React, {Component, CSSProperties, RefObject} from 'react';
import {ClassNameFormatter, cn} from '@bem-react/classname';
import {getContentHash} from '../../helpers/getContentHash';
import {TEXTCONTENT_URL_BASE} from '../../constants/constants';

export interface IPlayerProps {
    src: string;
}

const player: ClassNameFormatter = cn('Player');

export class Player extends Component<IPlayerProps> {
    playList: string[] = [];
    currentTrackNumber: number = 0;
    currentId: number = 0;
    isPlaying: boolean = false;
    private myRef: RefObject<HTMLAudioElement>;

    constructor(props: IPlayerProps) {
        super(props);
        this.myRef = React.createRef();
    }
    onTrackChange: (n: number, playing: boolean) => void = () => {};

    playingToggle = () => {
        if (this.myRef.current) {
            if (this.myRef.current.paused) {
                this.myRef.current.play();
                this.isPlaying = !this.myRef.current.paused;
                this.onTrackChange(this.currentTrackNumber, this.isPlaying);
            } else {
                this.myRef.current.pause();
                this.isPlaying = !this.myRef.current.paused;
                this.onTrackChange(this.currentTrackNumber, this.isPlaying);
            }
        }
    };

    playCurrentTrack = () => {
        const currentTrackLink = this.playList[this.currentTrackNumber];
        this.myRef.current &&
        this.myRef.current.setAttribute('src', currentTrackLink);
        this.myRef.current && this.myRef.current.play();
        this.myRef.current && this.onTrackChange(this.currentTrackNumber, !this.myRef.current.paused);
    };

    setPlayList = (id: number, data: string[], onTrackChange: (n: number, playing: boolean) => void) => {

        if (this.currentId !== id) {
            this.playList = data;
            this.onTrackChange = onTrackChange;
            this.currentTrackNumber = 0;
            this.playCurrentTrack();
            this.currentId = id;
        } else {
            this.playingToggle();
        }
    };

    setNext = () => {
        this.currentTrackNumber =  (this.currentTrackNumber + 1) % this.playList.length;
        this.playCurrentTrack();
    };

    setPrev = () => {
        this.currentTrackNumber =  (this.currentTrackNumber - 1 + this.playList.length) % this.playList.length;
        this.playCurrentTrack();
    };

    render() {
        return(
            <audio controls={false} ref={this.myRef} onEnded={this.setNext} preload={'metadata'}>
                <source src={this.props.src} type={'audio/mpeg'}/>
                Ваш браузер не поддерживает элемент <code>audio</code>.
            </audio>
        )
    }
}
