import React, {Component, ReactNode, RefObject} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';
import {RouteComponentProps} from 'react-router';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';

import {ICategory, Languages} from '../../types/types';
import {CategoryPage, ICategoryPageRouteProps} from '../CategoryPage/CategoryPage';
import {ContentPageContainer, IContentPageRouteProps} from '../ContentPage/ContentPageContainer';
import {ErrorPage} from '../ErrorPage/ErrorPage';
import {FooterContainer} from '../Footer/FooterContainer';
import {MainPage} from '../MainPage/MainPage';
import {OfferPageContainer} from '../OfferPage/OfferPageContainer';
import {HorizontalPreloader} from '../Preloader/HorizontalPreloader';
import {
    IPressContentPageRouteProps,
    PressContentPageContainer,
} from '../Press/PressContentPage/PressContentPageContainer';

import './App.css';
import {SubscriptionPageContainer} from '../SubscriptionPage/SubscriptionPageContainer';
import {PRESS_ALIAS} from '../../constants/constants';
import {Player} from '../Player/Player';

export interface IAppStateProps {
    datas: {
        [lang: string]: ICategory[] | [] | undefined;
    }
    data: ICategory[] | [] | undefined;
    categoryLoaded: boolean;
    error?: boolean;
    textLoaded: boolean;
    authorised?: boolean;
    lang: Languages;
}

export interface IAppDispatchProps {
    onInit(lang: Languages): void;
    onChange(lang: Languages): void;
}

export interface IAppProps extends IClassNameProps, IAppStateProps, IAppDispatchProps {
}

const app: ClassNameFormatter = cn('App');
export const PlayerContext = React.createContext<RefObject<Player> | null>(null);

export class App extends Component <IAppProps> {
    componentDidMount() {
        const lang: Languages = this.props.lang;

        this.props.datas[lang] === undefined &&
            this.props.onInit(this.props.lang);
    };

    componentDidUpdate(prevProps: IAppProps) {
        const lang: Languages = this.props.lang;
        (lang !== prevProps.lang) && (this.props.datas[lang] === undefined) &&
        this.props.onInit(this.props.lang);
    };

    renderMainPage = (props: RouteComponentProps): ReactNode => {
        const {error, categoryLoaded, data, lang, datas} = this.props;
        if (!this.props.categoryLoaded) {
            return (<HorizontalPreloader/>);
        } else if (error || !categoryLoaded || data && data[0] === undefined) {
            return <ErrorPage/>
        } else if (categoryLoaded && datas[lang] !== undefined) {
            return (
                <MainPage categoriesData={data}/>
            )
        }
        return (<HorizontalPreloader/>);
    };

    renderCategoryPage = (props: RouteComponentProps<ICategoryPageRouteProps>): ReactNode => {
        const {error, categoryLoaded, data, lang} = this.props;
        if (error && !categoryLoaded) {
            return <ErrorPage/>
        } else if (data !== undefined && categoryLoaded) {
            return (
                <CategoryPage {...props} categoriesData={data}/>
            )
        } else if (!this.props.categoryLoaded) {
            return (<HorizontalPreloader/>);
        }
        return (<HorizontalPreloader/>);
    };

    renderContentPage = (props: RouteComponentProps<IContentPageRouteProps>): ReactNode => {
        const {error, categoryLoaded, data, lang} = this.props;
        if (error && !categoryLoaded) {
            return <ErrorPage/>
        } else if (data !== undefined && categoryLoaded) {
            return (
                <ContentPageContainer {...props} categoriesData={data} onLoad={() => {
                }} onCheck={() => {
                }}/>
            )
        } else if (!categoryLoaded) {
            return (<HorizontalPreloader/>);
        }
        return (<HorizontalPreloader/>);
    };

    renderPressContentPage = (props: RouteComponentProps<IPressContentPageRouteProps>): ReactNode => {
        if (this.props.data) {
            return (
                <PressContentPageContainer {...props} categoriesData={this.props.data} onLoad={() => {
                }}/>
            )
        } else {
            return null;
        }
        return (<HorizontalPreloader/>);
    };

    redirectToOffer = () => {
        let languageFromUrl: Languages | undefined;

        switch (location.pathname) {
            case '/offerru':
                languageFromUrl = Languages.ru;
                break;
            case '/offeren':
                languageFromUrl = Languages.en;
                break;
            case '/offeram':
                languageFromUrl = Languages.am;
                break;
            case 'offer':
            default:
                languageFromUrl = this.props.lang;
        }

        if (this.props.lang !== languageFromUrl) {
            this.props.onChange(languageFromUrl);
        }

        return (<Redirect to={'/offer'} />)
    };

    renderOffer = (): ReactNode => {
        return (
            <OfferPageContainer onLoad={(lang: Languages) => {
            }} categoriesData={this.props.data}/>
        )
    };

    renderFooter = (): ReactNode => {
        if (this.props.data) {
            return (
                <FooterContainer onLoad={(lang: Languages) => {
                }} categoriesData={this.props.data}/>
            )
        } else {
            return null;
        }
    };

    renderSubscription = (): ReactNode => {
        return (
            <SubscriptionPageContainer
                onLoad={(lang: Languages) => {
                }}
                categoriesData={this.props.data}
            />
        )
    };

    render() {
        const {authorised, lang} = this.props;
        // const lastUrl: string | undefined = Cookies.get('lastUrl');
        // const redirect = lastUrl ? <Redirect from='/' to={lastUrl}/> : null;
        return (
            <div className={app()} key={lang}>
                <BrowserRouter>
                    <div className={app('Wrapper')}>
                        {/*{authorised && redirect}*/}
                        <Switch>
                            <Route exact={true} path={'/'}
                                   render={(props: RouteComponentProps): ReactNode => {
                                       return this.renderMainPage(props);
                                   }}/>

                            <Route exact={true} path='/category/:alias'
                                   render={(props: RouteComponentProps<ICategoryPageRouteProps>): ReactNode => {
                                       return this.renderCategoryPage(props);
                                   }}/>
                            <Route exact={true} path={`/category/${PRESS_ALIAS}/content/:id`}
                                   render={(props: RouteComponentProps<IPressContentPageRouteProps>): ReactNode => {
                                       return this.renderPressContentPage(props);
                                   }}/>
                            <Route exact={true} path='/category/:alias/content/:id'
                                   render={(props: RouteComponentProps<IContentPageRouteProps>): ReactNode => {
                                       return this.renderContentPage(props);
                                   }}/>
                            <Route path='/(offerru|offerua)'
                                   children={() => {
                                       return this.redirectToOffer();
                                   }}/>
                            <Route path='/offer'
                                   render={(): ReactNode => {
                                       return this.renderOffer();
                                   }}/>
                            <Route path='/subscription'
                                   render={(): ReactNode => {
                                       return this.renderSubscription();
                                   }}/>
                        </Switch>
                        <Switch>
                            <Route path={`/category/${PRESS_ALIAS}/content/:id`} render={(): null => {
                                return null;
                            }}/>
                            <Route path='*'
                                   render={(): ReactNode => {
                                       return this.renderFooter();
                                   }}/>
                        </Switch>
                    </div>
                </BrowserRouter>
            </div>
        );
    }
}
