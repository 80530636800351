import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { dictionary} from './dictionary';
import {Languages} from './types/types';
import {getCurrentLangType, setLang} from './helpers/currentLanguageHelper';

i18n
    .use(initReactI18next)
    .init({
        resources: dictionary,
        lng: Languages.am,

        keySeparator: false,

        interpolation: {
            escapeValue: false,
        },
    })

setLang(getCurrentLangType());

export default i18n;
