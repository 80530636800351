import {PRESS_ALIAS} from "../constants/constants";

export function getCategoryButtonText (alias: string) {
    switch (alias) {
        case 'press':
            return 'вся пресса';
        case 'sport':
            return 'все про спорт';
        case 'health':
            return 'все про здоровье';
        case 'diet':
            return 'все про питание';
        case 'beauty':
            return 'все про красоту';
        case 'family':
            return 'все про семью';
        case 'style':
            return 'все про стиль';
        case PRESS_ALIAS:
            return 'вся пресса';
    }
}