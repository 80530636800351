import {Languages} from '../types/types';

export const CHANGE_LANGUAGE_START = 'CHANGE_LANGUAGE_START';
export const CHANGE_LANGUAGE_FINISH = 'CHANGE_LANGUAGE_FINISH';

export type CHANGE_LANGUAGE_START_TYPE = typeof CHANGE_LANGUAGE_START;
export type CHANGE_LANGUAGE_FINISH_TYPE = typeof CHANGE_LANGUAGE_FINISH;

export interface  IChangeLanguageStartAction {
    type: CHANGE_LANGUAGE_START_TYPE;
    payload: Languages;
}

export interface  IChangeLanguageFinishAction {
    type: CHANGE_LANGUAGE_FINISH_TYPE;
    payload: Languages;
}

export type LanguageAction = IChangeLanguageStartAction | IChangeLanguageFinishAction;
