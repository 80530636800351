import React, {Component} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {classnames} from '@bem-react/classnames';
import {IClassNameProps} from '@bem-react/core';
import {Link} from 'react-router-dom';

import {getJournalTitleByDate} from '../../../helpers/getJournalTitleByDate';
import {getContentLink} from '../../../helpers/getLinks';
import {IContentInfo, IPreview} from '../../../types/types';

import './PressContentItem.css';
import {PRESS_ALIAS} from '../../../constants/constants';

const pressContentItem: ClassNameFormatter = cn('PressContentItem');

export interface IPressContentItemStateProps {
    language: string;
    authorised?: boolean;
}

export interface IPressContentItemProps extends IClassNameProps, IPressContentItemStateProps {
    content: IContentInfo;
}

export class PressContentItem extends Component<IPressContentItemProps> {
    render() {
        const {content, language, authorised} = this.props;
        const month = getJournalTitleByDate(content, language);
        const link = getContentLink(PRESS_ALIAS, content.id);
        const preview = content.previews.filter((preview: IPreview) => preview.type.includes('normal'));
        return (
            <Link to={link} className={classnames(pressContentItem({premium: !authorised}), this.props.className)}>
                <p className={pressContentItem('Title')}>{month}</p>
                <div className={pressContentItem('ImageWrap')}>
                    <img data-src={`/storage/d/${preview[0].link}`}
                         className={classnames('swiper-lazy', pressContentItem('Image'))}/>
                    <div className="swiper-lazy-preloader"></div>
                </div>
            </Link>
        )
    }
}
