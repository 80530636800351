import {IClassNameProps} from '@bem-react/core';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';

import {LOAD_SUBSCRIPTION_START} from '../../actions/subscriptionAction';
import {IAppStore, ICategory, Languages} from '../../types/types';

import {ISubscriptionPageStateProps, SubscriptionPage} from './SubscriptionPage';

export interface IOwnProps extends IClassNameProps {
    categoriesData?: ICategory[];
}

const mapStateToProps = (store: IAppStore, ownProps: IOwnProps): ISubscriptionPageStateProps => {
    return {
        subscriptionText: store.subscriptionText.text[store.lang],
        subscriptionTexts: store.subscriptionText.text,
        error: store.subscriptionText.error,
        textLoaded: store.subscriptionText.textLoaded,
        language: store.lang,
    }
};

const mapDispatchToProps = (dispatch: Dispatch): {} => ({
    onLoad: (lang: Languages) => {
        dispatch({type: LOAD_SUBSCRIPTION_START, payload: {
            lang,
            }})
    },
});

export const SubscriptionPageContainer = connect(mapStateToProps, mapDispatchToProps)(SubscriptionPage);
