import {IContentInfo} from '../types/types';

import {IItemsOfJournalByYearsInterface} from './getJournalIndex';

export function getlatestJournals(journalIndex: IItemsOfJournalByYearsInterface): IContentInfo[] {
    const latestJournals = [];

    for (const key in journalIndex) {
        const latest = Math.max(...Object.keys(journalIndex[key].years).map(Number));

        latestJournals.push(journalIndex[key].years[latest][0]);
    }

    return latestJournals;
}
