import {IAppStore, IContentInfo} from '../../types/types';
import {IMusicContentItemProps, IMusicContentItemSateProps, MusicContentItem} from './MusicContentItem';
import {connect} from 'react-redux';
import {IClassNameProps} from '@bem-react/core';

export interface IOwnProps extends IClassNameProps {
    data: IContentInfo[]
    key: number;
    order: number;
    categoryAlias: string;
    page: string;
}

const mapStateToProps = (store: IAppStore, ownProps: IOwnProps): IMusicContentItemSateProps => {
    return {
        language: store.lang,
        authorised: store.section.authorized,
    }
}

export const MusicContentItemContainer = connect(mapStateToProps, null)(MusicContentItem);