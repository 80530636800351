export const LOAD_MANIFEST_START = 'LOAD_MANIFEST_START';
export const LOAD_MANIFEST_FINISH = 'LOAD_MANIFEST_FINISH';
export const LOAD_MANIFEST_FAIL = 'LOAD_MANIFEST_FAIL';

export type LOAD_MANIFEST_START_TYPE = typeof LOAD_MANIFEST_START;
export type LOAD_MANIFEST_FINISH_TYPE = typeof LOAD_MANIFEST_FINISH;
export type LOAD_MANIFEST_FAIL_TYPE = typeof LOAD_MANIFEST_FAIL;

export interface ILoadManifestStartAction {
    type: LOAD_MANIFEST_START_TYPE;
    payload: {
        hash: string;
        routeUrl: string;
    }
}

export interface  ILoadManifestFinishAction {
    type: LOAD_MANIFEST_FINISH_TYPE;
    hash: string;
    payload: string[];
}

export interface  ILoadManifestFailAction {
    type: LOAD_MANIFEST_FAIL_TYPE;
    payload: number;
    manifestError: boolean;
}

export function loadManifestFail(status: number): ILoadManifestFailAction {
    return {
        type: LOAD_MANIFEST_FAIL,
        payload: status,
        manifestError: true,
    }
}

export function loadManifestFinish(strings: string[], hash: string): ILoadManifestFinishAction {
    return {
        type: LOAD_MANIFEST_FINISH,
        hash,
        payload: strings,
    }
}

export type ManifestAction = ILoadManifestStartAction | ILoadManifestFinishAction | ILoadManifestFailAction;
