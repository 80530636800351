import {ContentAction, LOAD_CONTENT_FAIL, LOAD_CONTENT_FINISH, LOAD_CONTENT_START} from '../actions/contentAction';
import {IContentText} from '../types/types';

const initialStore: IContentText = {
    contentLoaded: false,
    contents: {},
    textError: false,
};

export function contentReducer(state: IContentText = initialStore, action: ContentAction): IContentText {
    switch (action.type) {
        case LOAD_CONTENT_START: {
            return {...state, contentLoaded: false}
        }
        case LOAD_CONTENT_FINISH: {
            return {
                ...state,
                contentLoaded: true,
                contents: {
                    ...state.contents,
                    [action.hash]: action.text,
                },
            }
        }
        case LOAD_CONTENT_FAIL: {
            return {...state, contentLoaded: false, textError: action.textError}
        }
    }
    return state;
}
