export const LOAD_SUBSCRIPTION_START = 'LOAD_SUBSCRIPTION_START';
export const LOAD_SUBSCRIPTION_FINISH = 'LOAD_SUBSCRIPTION_FINISH';
export const LOAD_SUBSCRIPTION_FAIL = 'LOAD_SUBSCRIPTION_FAIL';

export type LOAD_SUBSCRIPTION_START_TYPE = typeof LOAD_SUBSCRIPTION_START;
export type LOAD_SUBSCRIPTION_FINISH_TYPE = typeof LOAD_SUBSCRIPTION_FINISH;
export type LOAD_SUBSCRIPTION_FAIL_TYPE = typeof LOAD_SUBSCRIPTION_FAIL;

export interface ILoadSubscriptionStartAction {
    type: LOAD_SUBSCRIPTION_START_TYPE;
    payload: {
        lang: string;
    }
}

export interface ILoadSubscriptionFinishAction {
    type: LOAD_SUBSCRIPTION_FINISH_TYPE;
    lang: string;
    text: string;
}

export interface ILoadSubscriptionFailAction {
    type: LOAD_SUBSCRIPTION_FAIL_TYPE;
    payload: number;
    error: boolean;
}

export type SubscriptionAction = ILoadSubscriptionStartAction |
    ILoadSubscriptionFinishAction |
    ILoadSubscriptionFailAction;
