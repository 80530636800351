export function getCategoryLink(alias: string) {
    const base = '/category/';

    return base + alias;
}

export function getContentLink(alias: string, id: number) {
    const categoryLink = getCategoryLink(alias);
    const base = '/content/';

    return categoryLink + base + id;
}
