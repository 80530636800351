import React, {Component} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {classnames} from '@bem-react/classnames';
import {IClassNameProps} from '@bem-react/core';
import {Translation} from 'react-i18next';
import {Link} from 'react-router-dom';

import {ARM_LANG, ENG_LANG, RUS_LANG} from '../../constants/constants';
import {ICategory, Languages} from '../../types/types';
import {CloseButton} from '../Buttons/CloseButton/CLoseButton';
import {HamburgerButton} from '../Buttons/HumburgerButton/HumburgerButton';
import logo from '../Icons/bw_logo.svg';
import {Menu} from '../Menu/Menu';

import './Header.css';

export interface IHeaderState {
    open: boolean;
    selectOpen: boolean;
}

export interface IHeaderDispatchProps {
    onChange(lang: Languages): void;
}

export interface IHeaderStateProps {
    page: string;
    categoriesData: ICategory[];
    categoryAlias?: string;
    language: string;
}

export interface IHeaderProps extends IClassNameProps, IHeaderStateProps, IHeaderDispatchProps {
}

const header: ClassNameFormatter = cn('Header');

export class Header extends Component <IHeaderProps, IHeaderState> {
    state: IHeaderState = {
        open: false,
        selectOpen: false,
    };

    toggleMenu = () => this.setState({open: !this.state.open});
    toggleSelect = () => {
        this.setState({selectOpen: !this.state.selectOpen});
    }
    closeMenu = () => this.setState({open: false});

    public render() {
        const isVisible: boolean = this.state.open;
        const isSelectVisible: boolean = this.state.selectOpen;
        const {categoriesData, page, categoryAlias, language} = this.props;
        let selectedLanguage: string = '';
        let linkLanguage: string = '';
        switch (language) {
            case (Languages.ru):
            default:
                selectedLanguage = RUS_LANG;
                linkLanguage = 'ru';
                break;
            case (Languages.am):
                selectedLanguage = ARM_LANG;
                linkLanguage = 'am';
                break;
            case (Languages.en):
                selectedLanguage = ENG_LANG;
                linkLanguage = 'en';
                break;
        }
        console.log(linkLanguage);
        return (
            <header
                className={classnames(
                    header({ofPage: page, theme: categoryAlias, visible: isVisible}),
                    this.props.className)}>
                <div className={header('PersonalCabinet')}>
                    <Translation>
                        {
                            (t) => (
                                <>
                                    {t('ЛК')}
                                    &nbsp;
                                    <a href={`http://lk.sds.am/${linkLanguage}`}>
                                        {t('Ссылка')}
                                    </a>
                                </>
                            )
                        }
                    </Translation>
                </div>
                <div className={classnames(header('Wrapper'), 'Wrapper')}>
                    <div className={header('ButtonWrapper')}>
                    <HamburgerButton onClick={this.toggleMenu}
                                     className={classnames(header('HamburgerButton', {visible: !this.state.open}))}/>
                    <CloseButton onClose={this.toggleMenu}
                                 className={classnames(header('CloseButton', {visible: this.state.open}))}/>
                    </div>
                    <div className={header('LogoContainer')}>
                        <Link to='/' className={header('Link')}>
                            <img src={logo} className={header('Logo')}
                                 alt='logo'/>
                        </Link>
                    </div>
                    <Menu className={classnames(header('Menu', {visible: isVisible}))}
                          page={page}
                          categoriesData={categoriesData}
                          onClose={this.closeMenu}/>
                    <div className={header('Select')}>
                        <div className={header('SelectItem', {state: 'selected'})} onClick={this.toggleSelect}>
                            <div>{selectedLanguage}</div>
                        </div>
                        <ul className={header('SelectList', {visible: isSelectVisible})}>
                            <li
                                className={header('SelectItem', {active: language === Languages.ru})}
                                onClick={() => {
                                    this.toggleSelect();
                                    this.props.onChange(Languages.ru);
                                }}>
                                <span>{RUS_LANG}</span>
                            </li>
                            <li
                                className={header('SelectItem', {active: language === Languages.am})}
                                onClick={() => {
                                    this.toggleSelect();
                                    this.props.onChange(Languages.am);
                                }}>
                                <span>{ARM_LANG}</span>
                            </li>
                            <li
                                className={header('SelectItem', {active: language === Languages.en})}
                                onClick={() => {
                                    this.toggleSelect();
                                    this.props.onChange(Languages.en);
                                }}>
                                <span>{ENG_LANG}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
        );
    }
}
