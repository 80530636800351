import React, {Component, ReactNode, RefObject} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';
import {RouteComponentProps} from 'react-router-dom';

import {getMatchedCategory} from '../../helpers/getMatchedCategory';
import {ICategory, Languages, PageType} from '../../types/types';
import {CategoryItem} from '../CategoryItem/CategoryItem';

import './CategoryPage.css';
import './CategoryPage.css';
import {HeaderContainer} from '../Header/HeaderContainer';
import {Player} from "../Player/Player";
import {PlayerContext} from '../App/App';

export interface ICategoryPageRouteProps {
    alias: string;
}

export interface ICategoryPageProps extends RouteComponentProps<ICategoryPageRouteProps>, IClassNameProps {
    categoriesData: ICategory[];
}

const categoryPage: ClassNameFormatter = cn('CategoryPage');

export class CategoryPage extends Component<ICategoryPageProps> {
    private myRef: RefObject<Player>;

    constructor(props: ICategoryPageProps) {
        super(props);
        this.myRef = React.createRef();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    renderContent = (category: ICategory): ReactNode => {
        return (
            <CategoryItem className={categoryPage('CategoryItem')} name={category.name} id={category.id}
                          contentData={category.contents}
                          alias={category.alias} page={'category'}/>
        )
    };

    public render() {
        const {alias} = this.props.match.params;
        const {categoriesData} = this.props;
        if (categoriesData) {
            const matchedCategory: ICategory = getMatchedCategory(alias, categoriesData);
            return (
                <div className={categoryPage()}>
                    <Player src={''} ref={this.myRef}/>
                    <PlayerContext.Provider value={this.myRef}>
                    <HeaderContainer
                        className={categoryPage('Header')}
                        categoriesData={categoriesData}
                        page={PageType.typeCategory}
                        categoryAlias={alias}
                        onChange={(lang: Languages) => {
                        }}/>
                    <div className={categoryPage('Content')}>
                        {this.renderContent(matchedCategory)}
                    </div>
                    </PlayerContext.Provider>
                </div>
            )
        }

        return null;
    }
}
