import {IContentInfo, ITag} from '../types/types';
import {JOURNALS_TAG_TYPE} from '../constants/constants';

export interface IItemsOfJournalInterface {
    [id: string]: IContentInfo[];
}

export interface IItemsByYearsInterface {
    [year: string]: IContentInfo[];
}

export interface IItemsOfJournalByYearsInterface {
    [id: string]: {
        name: string;
        years: IItemsByYearsInterface;
    }
}

function getRevertDate(content: IContentInfo) {
    return (`${content.description.slice(0,4)}+${content.description.slice(5, 7)}`);
}

function compareStrings(a: IContentInfo, b: IContentInfo): number {
    const aDescription = getRevertDate(a);
    const bDescription = getRevertDate(b);
    if (aDescription > bDescription) {
        return -1;
    } else if (aDescription  < bDescription) {
        return 1;
    } else {
        return 0;
    }
}

function sortItemsOfJournal(items: IContentInfo[]) {
    items.sort(compareStrings);
}

function getNameIndexedContent(contents: IContentInfo[]) {
    const itemsOfJournal: IItemsOfJournalInterface = {};

    const filteredContent = contents.filter((elem) => {
        const correctType = elem.tags.filter((tag: ITag) => tag.type.includes(JOURNALS_TAG_TYPE));
        if (correctType.length > 0) {
            return true};
    });

    filteredContent.map((item) => {
        const [tag]: ITag[] = item.tags.filter((tagElem: ITag) => tagElem.type.includes(JOURNALS_TAG_TYPE));
        if (itemsOfJournal[tag.priority] === undefined) {
            itemsOfJournal[tag.priority] = [];
        }
        itemsOfJournal[tag.priority].push(item);
    });
    return itemsOfJournal;
}

export function getSortedJournalByYears(contents: IContentInfo[]) {
    const itemsOfJournal: IItemsOfJournalInterface = getNameIndexedContent(contents);


    for (const description in itemsOfJournal) {
        // сортировка по новизне, сначала новые


        sortItemsOfJournal(itemsOfJournal[description]);
    }
    return itemsOfJournal;
}

export function getJournalIndex(contents: IContentInfo[]) {
    const rightContent = contents.filter(content => {
        return (
            /^20[12][0-9]-[01][0-9]/.test(content.description)
        );
    });

    const itemsOfJournal: IItemsOfJournalInterface = getNameIndexedContent(rightContent);
    let itemsByYears: IItemsByYearsInterface = {};
    const itemsOfJournalByYears: IItemsOfJournalByYearsInterface = {};
    let journalName = '';

    for (const priority in itemsOfJournal) {
        // сортировка по новизне, сначала новые
        sortItemsOfJournal(itemsOfJournal[priority]);

        // получение объекта с компоновкой для отдельного журнала по годам
        itemsOfJournal[priority].map((item) => {
            const [tag]: ITag[] = item.tags.filter((tagElem: ITag) => tagElem.type.includes(JOURNALS_TAG_TYPE));
            journalName = tag.display_name;
            const { description }: IContentInfo = item;
            const year = description.slice(0, 4);

            if (itemsByYears[year] === undefined) {
                itemsByYears[year] = [];
            }
            itemsByYears[year].push(item);
        });

        // получение объекта с компоновкой по названию журнала и внутри него
        // компоновка по годам и сортировка по новизне
        if (!itemsOfJournalByYears[priority]) {
            itemsOfJournalByYears[priority] = {
                name: '',
                years: {},
            };
        };

        itemsOfJournalByYears[priority].name = journalName;
        itemsOfJournalByYears[priority].years =  itemsByYears;
        journalName = '';
        itemsByYears = {};
    }

    return itemsOfJournalByYears;
}