import React, {Component, ReactNode} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {classnames} from '@bem-react/classnames';
import {IClassNameProps} from '@bem-react/core';
import * as Cookies from 'js-cookie';
import {Link} from 'react-router-dom';

import {getCategoryLink, getContentLink} from '../../helpers/getLinks';
import {getRandomContentItem} from '../../helpers/getRandomContentItem';
import {ContentType, ICategory, IContentInfo, Languages, PageType} from '../../types/types';
import {HamburgerButton} from '../Buttons/HumburgerButton/HumburgerButton';
import {ContentAdditional} from '../ContentAdditional/ContentAdditional';
import {ContentDescription} from '../ContentDescription/ContentDescription';
import {ContentItemPreview} from '../ContentItemPreview/ContentItemPreview';
import {ContentText} from '../ContentText/ContentText';
import {ErrorPage} from '../ErrorPage/ErrorPage';
// компоненты
import {HorizontalPreloader} from '../Preloader/HorizontalPreloader';

import './ContentPage.css';
import {Translation} from 'react-i18next';
import {chooseAndTranslate} from '../../helpers/currentLanguageHelper';
import {HeaderContainer} from '../Header/HeaderContainer';
import {ContentItemPreviewContainer} from '../ContentItemPreview/ContentItemPreviewContainer';

// хэлперы

// стили

export interface IContentPageStateProps extends IClassNameProps {
    categoriesData: ICategory[];
    matchedCategory: ICategory;
    matchedContent: IContentInfo;
    alias: string;
    id: string;
    routeUrl: string;
    hash: string;
    text?: string;
    contentLoaded?: boolean;
    textError?: boolean;
    contents: {
        [key: string]: string;
    };
}

export interface IContentPageDispatchProps {
    onLoad(hash: string, routeUrl: string): void;
    onCheck(routeUrl: string): void;
}

export interface IContentPageProps extends IContentPageStateProps, IContentPageDispatchProps {
}

const contentPage: ClassNameFormatter = cn('ContentPage');

export class ContentPage extends Component<IContentPageProps> {
    componentDidMount() {
        window.scrollTo(0, 0);

        ((this.props.matchedContent.type === ContentType.type1) ||
        (this.props.matchedContent.type === ContentType.type2)) &&
        this.props.hash && this.props.contents[this.props.hash] === undefined &&
        this.props.onLoad(this.props.hash, this.props.routeUrl);

        (this.props.matchedContent.type === ContentType.type4) && this.props.onCheck(this.props.routeUrl);
    }

    componentDidUpdate(prevProps: IContentPageProps) {
        this.props.hash !== prevProps.hash && this.props.contents[this.props.hash] === undefined &&
        ((this.props.matchedContent.type === ContentType.type1) ||
        (this.props.matchedContent.type === ContentType.type2)) &&
        this.props.onLoad(this.props.hash, this.props.routeUrl);

        this.props.hash !== prevProps.hash && this.props.contents[this.props.hash] === undefined &&
        this.props.matchedContent.type === ContentType.type4 &&
        this.props.onCheck(this.props.routeUrl);
    }

    renderContentText = (description: string): ReactNode => {
        const {matchedContent, contentLoaded, hash, text} = this.props;

        if (contentLoaded &&
            (matchedContent.type === ContentType.type1 ||
            matchedContent.type === ContentType.type2)) {
            return (
                <div className={contentPage('ContentTextWrap')}>
                    <ContentText text={text} className={contentPage('ContentText')}/>
                </div>
            )
        } else if (!contentLoaded &&
            (matchedContent.type === ContentType.type1 ||
            matchedContent.type === ContentType.type2)) {
            return (<HorizontalPreloader/>)
        } else {
            let preview: string;
            matchedContent.previews[1] ?
                preview = `/storage/d/${matchedContent.previews[1].link}` :
                preview = `/storage/d/${matchedContent.previews[0].link}`;
            const src = '/gate/d/' + matchedContent.uidx + '/' + matchedContent.file_hash;
            return(
                <div className={contentPage('ContentTextWrap')}>
                    <video
                        className={contentPage('Video')} controls={true} controlsList='nodownload'
                        key={matchedContent.file_hash} poster={preview}>
                        <source src={src} type='video/mp4'
                                ref={() => Number(this.props.matchedContent.id)} />
                        Тег video не поддерживается вашим браузером
                    </video>
                </div>
            )
        }
    };

    render() {
        const {categoriesData, alias, id, matchedCategory, contentLoaded, matchedContent} = this.props;
        const linkToCategory = getCategoryLink(this.props.alias);

        if (this.props.textError && !this.props.contentLoaded) {
            return <ErrorPage/>
        } else {
            {
                Cookies.remove('lastUrl');
            }
            return (
                <div className={contentPage()}>
                    <HeaderContainer
                        className={contentPage('Header')}
                        categoriesData={categoriesData}
                        page={PageType.typeContent}
                        categoryAlias={alias}
                        onChange={(lang: Languages) => {
                        }}/>
                    <div className={contentPage('Content')}>
                        <div className={contentPage('MainBar')}>
                            <Link to={linkToCategory} className={contentPage('CategoryLink')}>
                                <div className={contentPage('CategoryLink-Btn', {size: 'S'})}/>
                            </Link>
                            <div className={contentPage('ContentWrap')}>
                                <ContentItemPreviewContainer id={matchedContent.id} key={matchedContent.id}
                                                    title={matchedContent.name} description={matchedContent.description}
                                                    tags={matchedContent.tags} order={matchedContent.id}
                                                    previews={matchedContent.previews}
                                                    type={matchedContent.type} url={matchedContent.file_hash}
                                                    categoryAlias={alias} page={contentPage()}
                                                             uidx={matchedContent.uidx} size={'common'}/>
                            </div>
                            {this.renderContentText(matchedContent.description)}
                        </div>
                        <div className={contentPage('AdditionalBar')}>
                            <div className={contentPage('ContentAdditionalWrapper')}>
                                <Translation>
                                    {
                                        (t) =>
                                        <h3 className={contentPage('ContentAdditionalTitle')}>{t('Ещё по теме')}</h3>
                                    }
                                </Translation>
                                <ContentAdditional className={contentPage('ContentAdditional')}
                                                   category={this.props.matchedCategory}
                                                   contentId={Number(this.props.id)}
                                                   page={contentPage()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}
