import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import {Dispatch} from 'redux';

import {LOAD_MANIFEST_START} from '../../../actions/pressContentAction';
import {PRESS_ALIAS} from '../../../constants/constants';
import {getBigPreview} from '../../../helpers/getBigPreview';
import {getContentHash} from '../../../helpers/getContentHash';
import {getJournalTitleByDate} from '../../../helpers/getJournalTitleByDate';
import {getMatchedCategory} from '../../../helpers/getMatchedCategory';
import {getMatchedContent} from '../../../helpers/getMatchedContent';
import {IAppStore, ICategory, IContentInfo} from '../../../types/types';

import {IPressContentPageStateProps, PressContentPage} from './PressContentPage';

export interface IPressContentPageRouteProps {
    id: string;
}

export interface IOwnProps extends RouteComponentProps<IPressContentPageRouteProps> {
    categoriesData: ICategory[];
}

const mapStateToProps = (store: IAppStore, ownProps: IOwnProps): IPressContentPageStateProps => {
    const routeUrl: string = ownProps.location.pathname;
    const { id } = ownProps.match.params;
    const { categoriesData } = ownProps;
    const matchedCategory: ICategory = getMatchedCategory(PRESS_ALIAS, categoriesData);
    const matchedContent: IContentInfo = getMatchedContent(id, matchedCategory.contents);
    const hash = getContentHash(matchedContent.file_hash, matchedContent.uidx);
    const name = matchedContent.name;
    const month = getJournalTitleByDate(matchedContent, store.lang);
    const firstPagePreview = getBigPreview(matchedContent);

    return {
        name,
        month,
        firstPagePreview,
        hash,
        routeUrl,
        categoriesData,
        language: store.lang,
        manifest: store.pressContent.manifests[hash],
        manifestLoaded: store.pressContent.manifestLoaded,
        manifestError: store.pressContent.manifestError,
    }
};

const mapDispatchToProps = (dispatch: Dispatch): {} => ({
    onLoad: (hash: string, routeUrl: string) => {
        dispatch({type: LOAD_MANIFEST_START, payload: {
                hash,
                routeUrl,
            },
        })
    },
});

export const PressContentPageContainer = connect(mapStateToProps, mapDispatchToProps)(PressContentPage);
