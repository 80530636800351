export enum PreviewType {
    type1 = 'main',
    type2 = 'middle',
    type3 = 'small',
}

export enum ContentType {
    type1 = 'html',
    type2 = 'sf_section',
    type3 = 'journal',
    type4 = 'sf_any_content',
}

export enum PageType {
    typeMain = 'MainPage',
    typeCategory = 'CategoryPage',
    typePress = 'PressPage',
    typeContent = 'ContentPage',
    typeOffer = 'OfferPage',
    typeSubscription = 'SubscriptionPage',
}

export enum Languages {
    ru = 'ru-RU',
    am = 'hy-AM',
    en = 'en-GB',
}

export interface IMultiName {
    ru: string;
    am: string;
    en: string;
}

export interface ITag {
    id: number;
    type: string;
    display_name: string;
    // display_name: IMultiName;
    priority: number;
}

export interface ICategory {
    id: number;
    name: string;
    alias: string;
    contents: IContentInfo[];
}

// export interface IContentInfoFromResponse {
//     description: string;
//     id: number;
//     name: string;
//     type: ContentType;
//     tags: ITagFromResponse[];
//     file: string;
//     file_hash: string
//     previews: IPreview[];
//     priority: number;
//     uidx: string;
// }

export interface IContentInfo {
    description: string;
    id: number;
    name: string;
    type: ContentType;
    tags: ITag[];
    file_hash: string;
    previews: IPreview[];
    uidx: string;
}

export interface IPreview {
    id: number;
    type: PreviewType;
    link: string;
}

export interface IMappedDataResponse {
    name: string;
    categories: ICategory[];
    authorized?: boolean;
    status?: string;
}

export interface ISection {
    categoryLoaded: boolean;
    categories: {
        // todo почему нельзя в тип lang Language?
        [lang: string]: ICategory[] | [] | undefined;
    };
    error?: boolean;
    authorized?: boolean;
}

export interface IContentText {
    contentLoaded: boolean;
    contents: {
        [key: string]: string;
    }
    textError?: boolean;
}

export interface IPressContentManifest {
    manifestLoaded: boolean;
    manifests: {
        [key: string]: string[];
    }
    manifestError: boolean;
}

export interface ILoadedText {
    textLoaded: boolean;
    text: {
        [key: string]: string;
    }
    error?: boolean;
}

export interface IAppStore {
    section: ISection;
    content: IContentText;
    pressContent: IPressContentManifest;
    offerText: ILoadedText;
    footerText: ILoadedText;
    subscriptionText: ILoadedText;
    lang: Languages;
}
