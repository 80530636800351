import {Languages} from '../types/types';

// NzQ0MzA3MGRkZWYyYmU2OA для local
// OTdmMmNkOWIxOThiYzQxNQ для stage
// Mjg3NmYxMDI5NzM4ODMzZg для stable

export const CATEGORIES_URL: string = '/api/v5/compact/Mjg3NmYxMDI5NzM4ODMzZg';

export const DEFAULT_LANG = Languages.am;
export const HOME: string = '/';
export const TEXTCONTENT_URL_BASE: string = '/gate/d/';
export const TEXT_URL_BASE: string = '/text/am-vivacell-bewoman/';
export const OFFER_URL_BASE: string = 'http://lp.sds.am/lp/oferta/23';
export const FOOTER_URL: string = TEXT_URL_BASE + 'footer/';
export const SUBSCRIPTION_URL: string = TEXT_URL_BASE + 'pricepage/';
export const AUTH_LINK_AM = 'http://woman.sds.am/lp/flow/am-vivacell-bewoman?source=service';
export const AUTH_LINK_RU = 'http://woman.sds.am/lp/flow/am-vivacell-bewoman?source=service';
export const AUTH_LINK_EN = 'http://woman.sds.am/lp/flow/am-vivacell-bewoman?source=service';

// aliases
export const PRESS_ALIAS = 'pressa-ru-ua-pdf';
export const MUSIC_TAG_TYPE = 'music_import_';
export const COMMON_CONTENT_CARD_TYPE = 'content';
export const MUSIC_CONTENT_CARD_TYPE = 'music';
export const JOURNALS_TAG_TYPE = 'ji_journal_';
export const RUS_LANG = 'рус';
export const ARM_LANG = 'հայ';
export const ENG_LANG = 'eng';

// numbers constant
export const AMOUNT_OF_CONTENTITEMS_FOR_MAINPAGE = 8;
