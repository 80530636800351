import React, {Component} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {classnames} from '@bem-react/classnames';
import {IClassNameProps} from '@bem-react/core';
import {Link} from 'react-router-dom';

import {getCategoryLink} from '../../helpers/getLinks';
import {ICategory, Languages} from '../../types/types';

import logo from '../Icons/bw_logo.svg';
import './Footer.css';
import {HorizontalPreloader} from '../Preloader/HorizontalPreloader';
import {Translation} from 'react-i18next';
import {OFFER_URL_BASE, PRESS_ALIAS} from '../../constants/constants';

export interface IFooterStateProps extends IClassNameProps {
    footerText?: string;
    footerTexts: {
        [key: string]: string;
    };
    error?: boolean;
    textLoaded: boolean;
    language: Languages;
    categoriesData?: ICategory[];
}

export interface IFooterDispatchProps {
    onLoad(lang: Languages): void;
}

export interface IFooterProps extends IFooterStateProps, IFooterDispatchProps {
}

const footer: ClassNameFormatter = cn('Footer');

export class Footer extends Component <IFooterProps> {
    currentYear = new Date().getFullYear();

    componentDidMount() {
        const lang: Languages = this.props.language;
        this.props.footerTexts[lang] === undefined && this.props.onLoad(lang);
    }

    componentDidUpdate(prevProps: IFooterProps) {
        const lang: Languages = this.props.language;
        lang !== prevProps.language && this.props.footerTexts[lang] === undefined
        && this.props.onLoad(this.props.language);
    }

    createMarkup = (jsonString: any) => {
        return {__html: jsonString};
    };

    renderText = () => {
        if (this.props.error) {
            return null;
        }
        if (this.props.textLoaded && this.props.footerText) {
            return (
                <div className={footer('Text')} dangerouslySetInnerHTML={this.createMarkup(this.props.footerText)} />
            );
        } else {
            return (<HorizontalPreloader />);
        }
    };

    renderCategoriesLink = () => {
        const {categoriesData} = this.props;

        if (categoriesData !== undefined) {
            const newCategoriesList: ICategory[] = [];
            // категория "пресса" д.б. первой
            categoriesData.map((category: ICategory) => {
                if (category.alias === PRESS_ALIAS) {
                    newCategoriesList.unshift(category);
                } else {
                    newCategoriesList.push(category);
                }
            });

            return (
                newCategoriesList.map((category: ICategory, index) => {
                    const linkToCategory = getCategoryLink(category.alias);
                    return (
                        <Link className={classnames(footer('Link'), footer('CategoryLink'))} to={linkToCategory}
                              key={index}>
                            <Translation>
                                {
                                    (t) =>
                                        <div>{t(category.name)}</div>
                                }
                            </Translation>
                        </Link>
                    )
                })
            )
        }
    };

    public render() {
        const lang = this.props.language;
        let param;

        switch (lang) {
            case (Languages.ru):
            default:
                param = 'ru';
                break;
            case (Languages.am):
                param = 'am';
                break;
            case (Languages.en):
                param = 'en';
                break;
        }

        const offerLink = `${OFFER_URL_BASE}/${param}`;
        return (
            <footer className={classnames(footer(), this.props.className)}>
                <div className={footer('Wrapper')}>
                    <Translation>
                        {
                            (t) =>
                                <Link to='/subscription' className={footer('SubscriptionLink')}>
                                    {t('Правила подписки')}
                                </Link>
                        }
                    </Translation>
                    <Translation>
                        {
                            (t) =>
                                <a href={offerLink} className={footer('OfferLink')}>
                                    {t('Условия предоставления контент-услуги')} "Be Woman"
                                </a>
                        }
                    </Translation>
                    {this.renderText()}
                    <div className={footer('CategoriesLinkWrap')}>
                        {this.renderCategoriesLink()}
                    </div>
                    <div className={footer('LogoContainer')}>
                        <Link to='/' className={footer('LogoLink')}>
                            <img src={logo} className={footer('Logo')}
                                 alt='logo'/>
                        </Link>
                        <span>&#174;{this.currentYear}</span>
                    </div>
                </div>
            </footer>
        );
    }
}
