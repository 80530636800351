import React, {Component, ReactNode} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';

import './ContentListPartial.css';
import {ContentItemPreviewContainer} from '../ContentItemPreview/ContentItemPreviewContainer';
import {IContentCard} from "../../helpers/contentHelper";
import {COMMON_CONTENT_CARD_TYPE, MUSIC_CONTENT_CARD_TYPE} from "../../constants/constants";
import {MusicContentItemContainer} from "../MusicContentItem/MusicContentItemContainer";

export interface IContentListPartial extends IClassNameProps {
    cards: IContentCard[];
    alias: string;
    page: string;
}

const contentListPartial: ClassNameFormatter = cn('ContentListPartial');

export class ContentListPartial extends Component <IContentListPartial> {

    getContentItems = (content: IContentCard[]) => {
        const {alias} = this.props;
        const musicContent = content.filter((contentItem: IContentCard) => contentItem.cardType === MUSIC_CONTENT_CARD_TYPE);

        return (
            content.map((contentItem: IContentCard, index) => {
                const {cardType, data} = contentItem;
                let size: string = '';

                if(musicContent.length !== 0) {
                    size = (index === 0 || cardType === MUSIC_CONTENT_CARD_TYPE) ? 'big' : 'small';
                } else {
                    size =  (index === 0 || index === 7) ? 'big' : 'small';
                }

                if (cardType === COMMON_CONTENT_CARD_TYPE) {
                    const [contentData] = data;
                return (
                    <ContentItemPreviewContainer className={contentListPartial('Item', {size: size})}
                                        id={contentData.id}
                                        key={contentData.id}
                                        title={contentData.name}
                                        description={contentData.description}
                                        tags={contentData.tags}
                                        order={index}
                                        previews={contentData.previews}
                                        type={contentData.type}
                                        url={contentData.file_hash}
                                        categoryAlias={alias}
                                        page={this.props.page}
                                        uidx={contentData.uidx}
                                        size={size}
                                        />
                )
                    } else {
                    return (
                        <MusicContentItemContainer className={contentListPartial('Item', {type: cardType, size: size})} key={index}
                                                   order={index} categoryAlias={alias} page={this.props.page} data={data}
                        />
                    )
                }
            })
        )
    };

    render() {
        const {cards} = this.props;

        return(
            <div className={contentListPartial()}>
                <div className={contentListPartial('ItemsWrapper')}>
                    {this.getContentItems(cards)}
                </div>
            </div>
        )
    }

}
