import {IClassNameProps} from '@bem-react/core';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';

import {LOAD_OFFER_START} from '../../actions/offerAction';
import {IAppStore, ICategory, Languages} from '../../types/types';

import {IOfferPageStateProps, OfferPage} from './OfferPage';

export interface IOwnProps extends IClassNameProps {
    categoriesData?: ICategory[];
}

const mapStateToProps = (store: IAppStore, ownProps: IOwnProps): IOfferPageStateProps => {
    return {
        offerText: store.offerText.text[store.lang],
        offerTexts: store.offerText.text,
        error: store.offerText.error,
        textLoaded: store.offerText.textLoaded,
        language: store.lang,
    }
};

const mapDispatchToProps = (dispatch: Dispatch): {} => ({
    onLoad: (lang: Languages) => {
        dispatch({type: LOAD_OFFER_START, payload: {
            lang,
            }})
    },
});

export const OfferPageContainer = connect(mapStateToProps, mapDispatchToProps)(OfferPage);
