import React, {Component, CSSProperties, RefObject} from 'react';
import {ClassNameFormatter, cn} from '@bem-react/classname';
import {IContentInfo, Languages} from '../../types/types';
import {Player} from '../Player/Player';
import {PlayerContext} from '../App/App';
import {classnames} from '@bem-react/classnames';
import './MusicContentItem.css';
import {getContentHash} from '../../helpers/getContentHash';
import {
    AUTH_LINK_AM, AUTH_LINK_EN,
    AUTH_LINK_RU,
    MUSIC_TAG_TYPE,
    TEXTCONTENT_URL_BASE,
} from '../../constants/constants';
import {IOwnProps} from './MusicContentItemContainer';

export interface IMusicContentItemSateProps {
    authorised?: boolean;
    language: Languages;
}

export interface IMusicContentItemProps extends IMusicContentItemSateProps, IOwnProps {
}

const musicContentItem: ClassNameFormatter = cn('MusicContentItem');

const getTrackUrl = (item: IContentInfo): string => {
    return `${TEXTCONTENT_URL_BASE}${getContentHash(item.file_hash, item.uidx)}`;
};

export interface IMusicContentItemState {
    currentTrackNumber: number;
    isPlaying: boolean;
}

export class MusicContentItem extends Component<IMusicContentItemProps,
    IMusicContentItemState> {
    id: number = Date.now();

    state: IMusicContentItemState = {
        currentTrackNumber: 0,
        isPlaying: false,
    };

    renderContent = (ref: RefObject<Player> | null) => {
        const contentWithPreview = this.props.data.filter((item: IContentInfo) => item.previews.length !== 0)

        const preview = contentWithPreview[0].previews[0].link || null;

        const itemStyle: CSSProperties = {
            backgroundImage: `url(/storage/d/${preview})`,
            backgroundSize: 'cover',
            backgroundPositionY: '50%',
            backgroundPositionX: '50%,',
            backgroundRepeat: 'no-repeat',
        };

        const currentTrack = this.props.data[this.state.currentTrackNumber];
        const currentTrackTag = currentTrack.tags.filter((tag) => tag.type.includes(MUSIC_TAG_TYPE));
        const playListName = currentTrackTag[0].display_name;
        let authLink = '';

        switch (this.props.language) {
            case (Languages.ru):
                authLink = AUTH_LINK_RU;
                break;
            case (Languages.am):
                authLink = AUTH_LINK_AM;
                break;
            case (Languages.en):
                authLink = AUTH_LINK_EN;
                break;
            default:
                authLink = AUTH_LINK_RU;
        };

        return (
            <div className={classnames(musicContentItem(), this.props.className)} style={itemStyle}>
                <div className={musicContentItem('InfoWrapper')}>
                    <p className={musicContentItem('PlayListName')}>{playListName}</p>
                    <p className={musicContentItem('TrackName')}>{currentTrack.description} - {currentTrack.name}</p>
                </div>
                <div className={musicContentItem('ControlsWrapper')}>
                    <div className={musicContentItem('Btn', {type: 'prev'})}
                         onClick={() => {
                             !this.props.authorised && (document.location.href = authLink);
                             ref && ref.current && ref.current.setPrev()
                         }}
                    />
                    <div className={musicContentItem('Btn', {type: 'toggle', isPlaying: this.state.isPlaying})}
                         onClick={() => {
                             !this.props.authorised && (document.location.href = authLink);
                             ref && ref.current &&
                             ref.current.setPlayList(this.id,
                                 this.props.data.map(getTrackUrl), (n: number, playing: boolean) => {
                                     this.setState({
                                         currentTrackNumber: n, isPlaying: playing,
                                     })
                                 })
                         }}/>
                    <div className={musicContentItem('Btn', {type: 'next'})}
                         onClick={() => {
                             !this.props.authorised && (document.location.href = authLink);
                             ref && ref.current && ref.current.setNext()
                         }}
                    />
                </div>
            </div>
        )
    };

    render() {
        return (
            <PlayerContext.Consumer>
                {this.renderContent}
            </PlayerContext.Consumer>
        )
    }
}