export const dictionary = {
    'hy-AM': {
        translation: {
            'вся пресса': 'ամբողջ մամուլը',
            'все про спорт': 'ամեն ինչ սպորտի մասին',
            'все про здоровье': 'ամեն ինչ առողջության մասին',
            'все про питание': 'ամեն ինչ սննդի մասին',
            'все про красоту': 'ամեն ինչ գեղեցկության մասին',
            'все про семью': 'ամեն ինչ ընտանիքի մասին',
            'все про стиль': 'ամեն ինչ ոճի մասին',
            'На главную': 'Գլխավոր էջ',
            'Ещё по теме': 'Նույն թեմայով',
            'Чтиво': 'Ընթերցելու',
            'Видео': 'Տեսանյութեր',
            'Условия предоставления контент-услуги': 'Կոնտենտ ծառայությունների մատուցման պայմանները',
            'Правила подписки': 'Բաժանորդագրման պայմանները',
            'Загрузка страницы...': 'Էջի ներբեռնում...',
            'Журнал загружается. Пожалуйста, подождите!': 'Ամսագիրը ներբեռնվում է: Խնդրում ենք սպասել',
            'Пожалуйста, подождите!': 'Խնդրում ենք մի քիչ սպասել',
            'Что-то': 'Ինչ-որ',
            'пошло не так': 'բան սխալ է',
            'Следующая статья': 'Հաջորդ հոդվածը',
            'Назад в раздел': 'Վերադառնալ բաժին',
            'Назад': 'Վերադառնալ',
            'Страница': 'Էջ',
            'из': 'ից',
            'Тег video не поддерживается вашим браузером': 'Տեսանյութը չի բացվում Ձեր բրաուզերում',
            'Пресса': 'Նորություններ',
            'Семья': 'Ընտանիք',
            'Питание': 'Սնունդ',
            'Здоровье': 'Առողջություն',
            'Стиль': 'Ոճ',
            'Спорт': 'Սպորտ',
            'Красота': 'Գեղեցկություն',
            'ЛК': 'Ծառայությունների կառավարում',
            'Ссылка': 'Անձնական հաշվում',
        },
    },
    'en-GB': {
        translation: {
            'вся пресса': 'all press',
            'все про спорт': 'everything about sport',
            'все про здоровье': 'everything about health',
            'все про питание': 'everything about food',
            'все про красоту': 'everything about beauty',
            'все про семью': 'everything about family',
            'все про стиль': 'everything about style',
            'На главную': 'Home page',
            'Ещё по теме': 'More about this',
            'Чтиво': 'Reading',
            'Видео': 'Video',
            'Условия предоставления контент-услуги': 'Terms and Conditions of the content service provision',
            'Правила подписки': 'Terms of subscription',
            'Загрузка страницы...': 'Page is loading...',
            'Журнал загружается. Пожалуйста, подождите!': 'Magazine is downloading. Please wait!',
            'Пожалуйста, подождите!': 'Please wait!',
            'Что-то': 'Something',
            'пошло не так': 'went wrong',
            'Следующая статья': 'Next article',
            'Назад в раздел': 'Back to section',
            'Назад': 'Back',
            'Страница': 'Page',
            'из': 'from',
            'Тег video не поддерживается вашим браузером': 'Your browser is not supporting video format\n',
            'Пресса': 'Press',
            'Семья': 'Family',
            'Питание': 'Food',
            'Здоровье': 'Health',
            'Стиль': 'Style',
            'Спорт': 'Sports',
            'Красота': 'Beauty',
            'ЛК': 'Manage the service in',
            'Ссылка': 'Personal Area',
        },
    },
    'ru-RU': {
        translation: {
            'ЛК': 'Управление услугой в',
            'Ссылка': 'Личном кабинете',
        },
    },
};
